:root {
    --main-white: #ffffff;
    --main-black: #000;
    --main-grey: #e7e9eb;
    --main-grey-text: #8B8B8B;
  }

.SideMenu
{
    width: 260px;
    height: 100vh;
    border-right: solid 1px var(--main-grey);
    display: grid;
    grid-template-rows: 7% 86% 7%;
}

.SideMenuHeader 
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.SideLogoHeader
{
    width: 200px;
    height: auto;
}

.SidetextHeader
{
    margin-bottom: 0px;
    margin-left: 5px;
    white-space: nowrap;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

.Sidetext2Header
{
    margin-bottom: 0px;
    margin-left: 5px;
    white-space: nowrap;
    color: var(--main-grey-text);
    font-family: 'Open Sans', sans-serif;
    font-size:12px
}

.SideMenuBody
{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.SideSeparator
{
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: var(--main-grey-text);
    margin-top: 10px;
}


.SideMenuFooter 
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.FooterTextSide
{
    color: var(--main-grey-text);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.SideBtnLogout 
{
    background: none;
    border: none;
    color: var(--main-grey-text);
}




/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 1000px) {
    .SideMenu
    {
       display: none; 
    }
}