:root 
{
    --main-white: #FFFFFF;
    --main-gray: #F5F2F7;
}

body
{
    margin: 0;
    padding: 0;
}

.LayoutDashboard
{
    background-color: var(--main-white);
    width: 100vw;
    height: 100vh;
    display: flex;
}

.MainDashboard
{
    width: calc(100vw - 260px);
    height: 100vh;
    background-color: var(--main-gray);
}

@media only screen and (max-width : 1000px) {
    .MainDashboard
    {
        width: 100vw;
    }
}