@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
.SideBtnOptionActive{display:flex;align-items:center;margin-top:10px;background:none;border:none;width:100%;height:35px;text-align:start;border-radius:5px;font-family:"Open Sans",sans-serif;background:#e1f1ff}.SideBtnOption{display:flex;align-items:center;margin-top:10px;background:none;border:none;width:100%;height:35px;text-align:start;border-radius:5px;font-family:"Open Sans",sans-serif}.IconOption{margin-right:5px;color:#8b8b8b}.IconOptionActive{margin-right:5px;color:#0072fb}.TextOption{margin-bottom:0px;color:#8b8b8b;font-size:14px}.TextOptionActive{margin-bottom:0px;color:#000;font-weight:500;font-size:14px}.SideBtnOption:hover{background:#e1f1ff}
:root {
    --main-white: #ffffff;
    --main-black: #000;
    --main-grey: #e7e9eb;
    --main-grey-text: #8B8B8B;
  }

.SideMenu
{
    width: 260px;
    height: 100vh;
    border-right: solid 1px #e7e9eb;
    border-right: solid 1px var(--main-grey);
    display: grid;
    grid-template-rows: 7% 86% 7%;
}

.SideMenuHeader 
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.SideLogoHeader
{
    width: 200px;
    height: auto;
}

.SidetextHeader
{
    margin-bottom: 0px;
    margin-left: 5px;
    white-space: nowrap;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

.Sidetext2Header
{
    margin-bottom: 0px;
    margin-left: 5px;
    white-space: nowrap;
    color: #8B8B8B;
    color: var(--main-grey-text);
    font-family: 'Open Sans', sans-serif;
    font-size:12px
}

.SideMenuBody
{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.SideSeparator
{
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #8B8B8B;
    color: var(--main-grey-text);
    margin-top: 10px;
}


.SideMenuFooter 
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.FooterTextSide
{
    color: #8B8B8B;
    color: var(--main-grey-text);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.SideBtnLogout 
{
    background: none;
    border: none;
    color: #8B8B8B;
    color: var(--main-grey-text);
}




/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 1000px) {
    .SideMenu
    {
       display: none; 
    }
}
:root 
{
    --main-white: #FFFFFF;
    --main-gray: #F5F2F7;
}

body
{
    margin: 0;
    padding: 0;
}

.LayoutDashboard
{
    background-color: #FFFFFF;
    background-color: var(--main-white);
    width: 100vw;
    height: 100vh;
    display: flex;
}

.MainDashboard
{
    width: calc(100vw - 260px);
    height: 100vh;
    background-color: #F5F2F7;
    background-color: var(--main-gray);
}

@media only screen and (max-width : 1000px) {
    .MainDashboard
    {
        width: 100vw;
    }
}
.LoginPage{display:flex;flex-direction:column;justify-content:center;align-items:center;height:100vh;background:linear-gradient(-45deg, #f8a701, #ffcc00, #ff9f0a, #ff453a);background-size:400% 400%;position:relative;-webkit-animation:change 10s ease-in-out infinite;animation:change 10s ease-in-out infinite}@-webkit-keyframes change{0%{background-position:0 50%}50%{background-position:100% 50%}100%{background-position:0 50%}}@keyframes change{0%{background-position:0 50%}50%{background-position:100% 50%}100%{background-position:0 50%}}.LoginCard{box-shadow:0 14px 28px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.22);min-height:600px;width:25vw;margin-top:20PX;margin-bottom:20px;background:#fff;border-radius:10px;display:flex;flex-direction:column;justify-content:center;align-items:center}.LoginInput{width:350px;height:45px;border:1px solid #ccc;border-radius:5px;margin-bottom:20px;padding-left:10px;font-size:18px}.LoginForm{margin-top:20px}.LoginH4{font-size:28px}.LoginH5{font-size:12px;color:#979797}.Micro{color:#fff}.LoginBtn{width:300px;height:50px;border-radius:15px;font-size:18px;background-color:#ff7d1d;color:#fff;border:none}@media(min-width: 300px){.LoginCard{min-height:500px;width:350px}.LoginBtn{width:200px;height:50px}.LoginInput{width:300px}}
.Tokencard{width:80%;height:auto;padding:20px;margin:10px;border-radius:8px;border:3px solid #e7e9eb;display:grid;grid-template-columns:70% 30%}.BorderGreen{border:3px solid green}.restante{color:red;font-weight:bold}.abonado{color:green;font-weight:bold}.qr{display:flex;justify-content:center;align-items:center}.TicketPreview{width:100%;margin-top:10px}.BtnSection{display:flex;flex-direction:column;padding:10px}@media only screen and (max-width: 1000px){.Tokencard{width:90%;grid-template-columns:60% 40%}.btn-a{margin-bottom:10px}}.Tickets{width:auto;height:auto;display:flex;flex-direction:column;justify-content:center;align-items:center}.TicketLogo{margin-top:30px;width:200px}
.Generate{width:100%;height:93vh;display:grid;grid-template-columns:60% 40%}.SecGen{display:flex;flex-direction:column;align-items:center;border-right:1px solid #e7e9eb}.tittok{margin-top:10px;margin-bottom:10px;width:80%;display:flex;justify-content:center;align-self:center;border-bottom:1px solid #e7e9eb}.bodytok{width:80%;margin-top:20px}.divbtn{width:100%;display:flex;align-items:center;justify-content:flex-end;margin-top:20px}.SelectSeller{width:100%;height:45px}.InputMount{width:100%;height:45px;border:2px solid #e7e9eb;border-radius:8px;font-size:18px;padding-left:10px}.TitleTok{color:#8b8b8b}.SecListTok{display:flex;flex-direction:column;align-items:center;height:vh(100);overflow:auto}.TitCosltTok{background-color:#e7e9eb;color:#8b8b8b;padding-top:20px;padding-bottom:20px;width:100%;text-align:center}.Nohay{width:100%;height:100%;display:flex;align-items:center;justify-content:center}@media only screen and (max-width: 1000px){.Generate{width:100%;display:flex;flex-direction:column}.SecListTok{margin-top:20px}}
.ModalCreate 
{
    display: flex;
    flex-direction: column;
}

.form_modal
{
    margin-bottom: 15px;
}

.form_line
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.surveyaddTextfield
{
    width: 100%;
    margin-bottom: 20px;
}

.answeraddTextfield
{
    width: 50%;
}

.uploadimage
{
    visibility: none;
}

.cellAnswer
{
    display: grid;
    border: gray 1px solid;
    border-radius: 15px;
    padding-left: 10px;
    align-items: center;
    height: 50px;
    margin: 5px;
    grid-template-columns: 10% 40% 40% 10%;
}


.Survey{width:85vw;height:93vh;background-color:#f5f2f9;display:grid;grid-template-columns:100%;grid-template-rows:100%}.SurveyCard{padding:10px}.SurveyHead{margin-top:10px;margin-bottom:20px;display:flex;flex-direction:row-reverse}
.CardMap{margin:5px;border:1px solid #d8d8d8;border-radius:5px;background-color:#fff}.Map{width:42vw;height:50vh}
.DetailSurvey{width:85vw;height:93vh;background-color:#f5f2f9;display:grid;grid-template-rows:50% 50%}.DetailRow{display:grid;grid-template-columns:50% 50%}.CardDetail{margin:10px}.map-container{height:400px}
.SurveyIA
{
    display: grid;
    grid-template-rows: 50% 50%;
}

.DataBaseA
{
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

.DataBaseB
{
    display: grid;
    grid-template-columns: 50% 50%;
}

.CardBaseData
{
    background-color: #FFF;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
