@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

$white: #ffffff;
$black: #000;
$gray: #e7e9eb;
$grayText: #8B8B8B;
$greenIcon: #1fee1f;
$backGray: #F6F6F6;
$backborder: #CDCDCD;

.Tokencard
{
    width: 80%;
    height:auto;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    border: 3px solid $gray;
    display: grid;
    grid-template-columns: 70% 30%;
}

.BorderGreen {
    border: 3px solid green;
}

.restante
{
    color: red;
    font-weight: bold;
}

.abonado
{
    color: green;
    font-weight:bold;
}

.qr
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.TicketPreview{
    width: 100%;
    margin-top: 10px;
}


.BtnSection
{
    display: flex;
    flex-direction: column;
    padding: 10px;
}

@media only screen and (max-width : 1000px) {
    .Tokencard
    {
        width: 90%;
        grid-template-columns: 60% 40%;
    }

    .btn-a
    {
        margin-bottom: 10px;
    }

}

.Tickets
{
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TicketLogo
{
    margin-top: 30px;
    width: 200px;
}