.LoginPage
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(-45deg, #f8a701, #ffcc00, #ff9f0a, #ff453a);
  background-size: 400% 400%;
  position: relative;
  animation: change 10s ease-in-out infinite;
}

@keyframes change {
  0%{
      background-position: 0 50%;
  }
  50%{
      background-position: 100% 50%;
  }
  100%{
      background-position: 0 50%;
  }
}

.LoginCard
{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    min-height: 600px;
    width: 25vw;
    margin-top: 20PX;
    margin-bottom: 20px;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.LoginInput
{
  width: 350px;
  height: 45px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  margin-bottom: 20px;
  padding-left: 10px;
  font-size: 18px;
}

.LoginForm
{
  margin-top: 20px;
}

.LoginH4
{
  font-size: 28px;
}

.LoginH5
{
  font-size: 12px;
  color: rgb(151, 151, 151);
}

.Micro
{
  color: white;
}

.LoginBtn
{
  width: 300px;
  height: 50px;
  border-radius: 15px;
  font-size: 18px;
  background-color: #ff7d1d;
  color: white;
  border: none;
}

@media (min-width: 300px) {
    .LoginCard
    {
        min-height: 500px;
        width: 350px;
    }

    .LoginBtn
    {
    width: 200px;
    height: 50px;
    }

    .LoginInput
    {
    width: 300px;
    }
}