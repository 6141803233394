.Survey
{
    width: calc(85vw);
    height: calc(93vh);
    background-color: #F5F2F9;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
}

.SurveyCard
{
    padding: 10px;
}

.SurveyHead
{
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row-reverse;
}

