.DetailSurvey
{
    width: calc(85vw);
    height: calc(93vh);
    background-color: #F5F2F9;
    display: grid;
    grid-template-rows: 50% 50%;
}

.DetailRow
{
    display: grid;
    grid-template-columns: 50% 50%;
}

.CardDetail
{
    margin: 10px;
}

.map-container {
    height: 400px;
    }
