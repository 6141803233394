.SurveyIA
{
    display: grid;
    grid-template-rows: 50% 50%;
}

.DataBaseA
{
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

.DataBaseB
{
    display: grid;
    grid-template-columns: 50% 50%;
}

.CardBaseData
{
    background-color: #FFF;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}