@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

$white: #ffffff;
$black: #000;
$gray: #e7e9eb;
$grayText: #8B8B8B;
$blueIcon: #0072FB;
$backGray: #F6F6F6;
$backborder: #CDCDCD;

.Generate
{
    width: 100%;
    height: 93vh;
    display: grid;
    grid-template-columns: 60% 40%;
}

.SecGen
{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid $gray;
}

.tittok
{
    margin-top: 10px;
    margin-bottom: 10px;
    width: 80%;
    display: flex;
    justify-content:center;
    align-self: center;
    border-bottom: 1px solid $gray;
}

.bodytok
{
    width: 80%;
    margin-top: 20px;
}

.divbtn
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.SelectSeller
{
    width: 100%;
    height: 45px;
}

.InputMount
{
    width: 100%;
    height: 45px;
    border: 2px solid $gray;
    border-radius: 8px;
    font-size: 18px;
    padding-left: 10px;
}

.TitleTok
{
    color: $grayText;
}

.SecListTok
{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: vh(100);
    overflow: auto;
}

.TitCosltTok
{
    background-color: $gray;
    color: $grayText;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    text-align: center;
}

.ListTokens
{
    
}

.Nohay
{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width : 1000px) {
    .Generate
    {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .SecListTok
    {
        margin-top: 20px;
    }

}