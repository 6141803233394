.CardMap
{
    margin: 5px;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 5px;
    background-color: white;
}

.Map
{
    width: 42vw;
    height:50vh;
}