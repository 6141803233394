.ModalCreate 
{
    display: flex;
    flex-direction: column;
}

.form_modal
{
    margin-bottom: 15px;
}

.form_line
{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.surveyaddTextfield
{
    width: 100%;
    margin-bottom: 20px;
}

.answeraddTextfield
{
    width: 50%;
}

.uploadimage
{
    visibility: none;
}

.cellAnswer
{
    display: grid;
    border: gray 1px solid;
    border-radius: 15px;
    padding-left: 10px;
    align-items: center;
    height: 50px;
    margin: 5px;
    grid-template-columns: 10% 40% 40% 10%;
}

