@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

$white: #ffffff;
$black: #000;
$gray: #e7e9eb;
$grayText: #8B8B8B;
$blueIcon: #0072FB;

.SideBtnOptionActive
{
    display: flex;
    align-items: center;
    margin-top: 10px;
    background: none;
    border: none;
    width: 100%;
    height: 35px;
    text-align: start;
    border-radius:5px;
    font-family: 'Open Sans', sans-serif;
    background: #E1F1FF;
}

.SideBtnOption
{
    display: flex;
    align-items: center;
    margin-top: 10px;
    background: none;
    border: none;
    width: 100%;
    height: 35px;
    text-align: start;
    border-radius:5px;
    font-family: 'Open Sans', sans-serif;
}

.IconOption
{
    margin-right: 5px;
    color: $grayText;
}

.IconOptionActive
{
    margin-right: 5px;
    color: $blueIcon;
}

.TextOption
{
    margin-bottom: 0px;
    color: $grayText;
    font-size: 14px;
}

.TextOptionActive
{
    margin-bottom: 0px;
    color: $black;
    font-weight: 500;
    font-size: 14px;
}

.SideBtnOption:hover
{
    background: #E1F1FF;
}
